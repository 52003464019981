<template>
  <section class="container pb-8">
    <div class="row">
      <div class="col-12 col-md-8 order-1 order-md-0">
        <iframe
          class="w-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.650615651642!2d121.51251461537882!3d25.04592834396633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a973287a07f9%3A0x1b43d392a3305641!2zMTAw5Y-w5YyX5biC5Lit5q2j5Y2A6aSo5YmN6LevOOiZnzQ!5e0!3m2!1szh-TW!2stw!4v1608138481524!5m2!1szh-TW!2stw"
          width="600"
          height="450"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
      <div class="col-12 col-md-4 mb-6 mb-md-0">
        <div>
          <img class="mb-5" src="@/assets/images/logo-mobile.png" alt="" />
          <p>客服專線：{{ data.novicesContactPhone }}</p>
          <p>服務地址：{{ data.novicesContactAddress }}</p>
          <p>營業時間：{{ data.novicesContactOpenTime }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetNovicesContact } from '@/api/Novices';

export default {
  data() {
    return {
      data: {
        novicesContactBuName: '',
        novicesContactAddress: '',
        novicesContactPhone: '',
        novicesContactOpenTime: '',
        novicesContactNote: '',
      },
    };
  },
  created() {
    this.GetData();
  },
  methods: {
    GetData() {
      window.document.title = '讀家雲端學院-聯絡我們';
      GetNovicesContact().then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
        }
      });
    },
  },
};
</script>

<style></style>
